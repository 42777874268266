@font-face {
    font-family: 'JohnsonDisplay-Bold';
    src: url('./fonts/JohnsonDisplay-Bold.otf');
    src: url('./fonts/JohnsonDisplay-Bold.otf') format('otf'),
        url('./fonts/JohnsonDisplay-Bold.otf') format('otf');
}

@font-face {
    font-family: 'JohnsonDisplay-Light';
    src: url('./fonts/JohnsonDisplay-Light.otf');
    src: url('./fonts/JohnsonDisplay-Light.otf') format('otf'),
        url('./fonts/JohnsonDisplay-Light.otf') format('otf');
}

@font-face {
    font-family: 'JohnsonDisplay-Medium';
    src: url('./fonts/JohnsonDisplay-Medium.otf');
    src: url('./fonts/JohnsonDisplay-Medium.otf') format('otf'),
        url('./fonts/JohnsonDisplay-Medium.otf') format('otf');
}

@font-face {
    font-family: 'JohnsonText-Bold';
    src: url('./fonts/JohnsonText-Bold.otf');
    src: url('./fonts/JohnsonText-Bold.otf') format('otf'),
        url('./fonts/JohnsonText-Bold.otf') format('otf');
}

@font-face {
    font-family: 'JohnsonText-Light';
    src: url('./fonts/JohnsonText-Light.otf');
    src: url('./fonts/JohnsonText-Light.otf') format('otf'),
        url('./fonts/JohnsonText-Light.otf') format('otf');
}

@font-face {
    font-family: 'JohnsonText-Medium';
    src: url('./fonts/JohnsonText-Medium.otf');
    src: url('./fonts/JohnsonText-Medium.otf') format('otf'),
        url('./fonts/JohnsonText-Medium.otf') format('otf');
}

@font-face {
    font-family: 'JohnsonText-Regular';
    src: url('./fonts/JohnsonText-Regular.otf');
    src: url('./fonts/JohnsonText-Regular.otf') format('otf'),
        url('./fonts/JohnsonText-Regular.otf') format('otf');
}